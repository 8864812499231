import React from 'react'
import { CustomizablephotosecStyles } from './Customizablephotosec.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function Customizablephotosec(props) {
  const { CustomizablephotoProps } = props.customizablephotodata
  const caseQuoteProp = CustomizablephotoProps.caseQuoteProps
  const {
    data: {
      customixzePic: {
        childImageSharp: { fluid },
      },
    },
  } = props
  return (
    <CustomizablephotosecStyles>
      <div className="container">
        <h2>{CustomizablephotoProps.heading}</h2>
        <div className="row">
          <div className="col-content">
            <p>{CustomizablephotoProps.para}</p>
          </div>
          <div className="col-img">
            <Image
              fluid={fluid}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 499,
              }}
            />
          </div>
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </CustomizablephotosecStyles>
  )
}

export default Customizablephotosec
