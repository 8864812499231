import styled from 'styled-components'
import { bodyFont, xl, lg, md } from 'config/variables'

export const RemotelycontrollsecStyles = styled.div`
  position: relative;
  margin-bottom: 136px;
  ${xl(`
    margin-bottom: 100px;
  `)}
  ${lg(`
    margin-bottom: 80px;
  `)}
  ${md(`
    margin-bottom: 40px;
  `)}
  .engagementContent {
    display: flex;
    justify-content: space-between;
    padding-bottom: 80px;
    ${xl(`
      padding-bottom: 30px;
    `)}
    ${lg(`
        padding-bottom: 30px;
    `)}
     ${md(`
         padding-bottom: 0px;
      `)}
    .left-part {
      max-width: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      ${lg(`
        max-width:139px;
      `)}
      ${md(`
        display:none;
      `)}
      .bottom-content {
        position: relative;
        padding-top: 21px;
        &:before {
          content: '';
          position: absolute;
          width: 55px;
          height: 3px;
          background: #3d3d3d;
          top: 0;
          left: 0;
        }
        h4 {
          font-size: 15px;
          font-weight: 600;
          font-family: ${bodyFont};
          margin-bottom: 6px;
          text-transform: uppercase;
          line-height: 21px;
        }
        p {
          font-size: 15px;
          letter-spacing: -0.16px;
          line-height: 21px;
          margin: 0;
        }
      }
    }
    .right-part {
      max-width: 867px;
      width: 100%;
      ${xl(`
        padding-left: 30px;
      `)}
      ${lg(`
        padding-left: 50px;
      `)}
      ${md(`
         padding-left: 0;
      `)}
      p {
        margin: 0px;
      }
    }
  }
  .track-content {
    margin-top: 40px;
   
    .row {
      margin-top: 74px;
      display: flex;
      justify-content: space-between;
      ${xl(`
         margin-top: 30px;
      `)}
      ${lg(`
          margin-top: 40px;
          flex-direction: column;
      `)}
      ${md(`
          margin-top: 20px;
      `)}
      .col {
        flex: 0 1 540px;
        &:first-child {
        ${xl(`
          flex: 0 0 380px;
        `)}
        ${lg(`
            flex: 1 1 auto;
            max-width:380px;
            margin-bottom:40px; 
        `)}
        ${md(`
            margin-bottom:10px; 
        `)}
        }
        &:last-child {
          ${xl(`
            flex: 1 1 auto;
            padding-left:40px;
          `)}
          ${lg(`
            flex: 1 1 auto;
          `)}
        }
        figure {
          margin: 0;
          margin-top: -15px;
          margin-left: -17px;
          ${xl(`
          margin-top: 0px;
          margin-left: -10px;
      `)}
        }
      }
    }
  }
`
