import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Casebanner from 'components/Casebanner/Casebanner'
import { RedbullPhotoboothProps } from 'components/Props/redbull-photobooth'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Customizablephotosec from 'components/Customizablephotosec/Customizablephotosec'
import Resultimgsection from 'components/Resultimgsection/Resultimgsection'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Keybenefitssec from 'components/Keybenefitssec/Keybenefitssec'
import SmallImgContentblk from 'components/SmallImgContentblk/SmallImgContentblk'
import Remotelycontrollsec from 'components/Remotelycontrollsec/Remotelycontrollsec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import { xl, lg } from 'config/variables'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'

export const RedbullPhotoboothPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #585764;
          }
        }
      }
    }
  }
  .casepower-section {
    .powerContentRow {
      padding-bottom: 0;
      ${xl(`
        padding-bottom: 30px;
      `)}
      ${lg(`
        padding-bottom: 30px;
      `)}
      &:last-child {
        ${xl(`
        padding-bottom: 0;
      `)}
        ${lg(`
        padding-bottom: 0;
      `)}
      }
    }
  }
`

const RedbullPhotobooth = props => {
  const { data } = props
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  let itempower = [
    props.data.getreadyshoot.childImageSharp.fluid,
    props.data.kioskipad.childImageSharp.fluid,
  ]

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="RedBull Photobooth"
        description="Simform build a flexible setup for Redbull Wings Team in terms of Photobooth that creates unique, cost-effective and easy to share content for multiple events."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/case-studies/redbull-photobooth/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/case-studies/redbull-photobooth/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <RedbullPhotoboothPage>
        <Casebanner
          bannerdata={RedbullPhotoboothProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={RedbullPhotoboothProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Customizablephotosec
            customizablephotodata={RedbullPhotoboothProps}
            {...props}
          />
        </div>
        {showContent && (
          <>
            <Keybenefitssec
              KeyBenefitsdata={RedbullPhotoboothProps}
              {...props}
            />
            <Resultimgsection
              resultimgsectiondata={RedbullPhotoboothProps}
              {...props}
            />
            <CasePowerTools
              CasePowerToolsdata={RedbullPhotoboothProps.CasePowerToolsProps}
              items={itempower}
            />
            <SmallImgContentblk
              smallImgcontentdata={RedbullPhotoboothProps}
              {...props}
              showFullimages={true}
            />
            <Remotelycontrollsec
              remotecontrolsecdata={RedbullPhotoboothProps}
              {...props}
            />
            <Relatedcaselist
              relatedcaselistdata={RedbullPhotoboothProps}
              {...props}
            />
            <Casecontactcardinfo
              Casecontactcardinfodata={RedbullPhotoboothProps}
            />
          </>
        )}
      </RedbullPhotoboothPage>
    </Layout>
  )
}

export default RedbullPhotobooth

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/redbull-photobooth@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customixzePic: file(relativePath: { regex: "/customizable-pic@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 575) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    redbulleventscreen: file(
      relativePath: { regex: "/redbulleventscreen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 745) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    KeybenefitsThumb: file(relativePath: { regex: "/our-goal-icon@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 285) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    kioskipad: file(relativePath: { regex: "/kioskipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 564) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    getreadyshoot: file(relativePath: { regex: "/getreadyshoot@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 569) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    scalefusion: file(relativePath: { regex: "/scalefusion@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 553) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    scalableArchitech: file(
      relativePath: { regex: "/scalable-architech@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 867) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel: file(relativePath: { regex: "/mixpanel@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/stubbswoottons-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/fih-related-case-studies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
