import React from 'react'

export const RedbullPhotoboothProps = {
  bannerProps: {
    heading: 'Redbull Photobooth',
    bannerPara: 'shortcut to get featured on Redbull website',
    BannerAlt: 'Redbull Photobooth Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Red Bull sponsors more than 1500 events each year. With an average of
        10,000 visitors per event, these events hold a great marketing
        opportunity for Redbull. To provide engaging brand experience, Redbull
        used Photobooths for event goers to record memories. They were using
        different vendors across cities making it bit difficult for them to
        manage transportation, installation, and troubleshooting.
        <br />
        <br />
        Redbull wanted a custom scalable solution that can easily accommodate
        their event engagement needs, extend this engagement to digital channels
        like social, and save money in the long run.
      </>
    ),
  },
  CustomizablephotoProps: {
    heading: 'Simform set out to build customizable photo booth technology',
    para: (
      <>
        When you are building a solution for one of the fortune 100 companies,
        you are carrying it’s reputation along with you and in the solution that
        you are building. There is no room for compromising the quality.
        <br />
        <br />
        You have to design the app architecture in such a way, that it can
        easily handle the visitors’ traffic during an ongoing event and ensures
        pleasant user experience.
      </>
    ),
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “Simform was able to take our vision and create something that has
            proven itself successful”
          </span>
        </>
      ),
    },
  },
  KeyBenefitsprops: {
    title: 'Our Goals',
    BenefitsList: [
      'Create a consistent and internationally scalable product experience',
      'Drive Red Bull social media engagement',
      'Generate analytics from data of thousands of visitors',
      'Connect social graph data with consumer buying data',
      'Have a product that was easy to customize, setup and break down',
    ],
    ButtonName: 'Get your goals done Now',
    ButtonLink: '/contact/',
  },
  ResultimgsectionProps: {
    heading: 'Result',
    content: (
      <>
        After months of collaboration, we created a ‘Photo-booth’ experience
        that Redbull Wings Team members can easily set up, configure on desktop
        and then execute on-site with iPads.
        <br />
        <br />
        The flexible setup allows Red Bull to create unique, cost-effective and
        shareable content for multiple events across the globe with relative
        ease.
        <br />
        <br />
        While we continue to work with the Red Bull team to add new features and
        make improvements to the platform, our photo booth solution is now a
        staple at each Red Bull event. So far, it has traveled to more than 1000
        events, taken more than 200,000 photos and produced thousands of shares
        across multiple social channels.
      </>
    ),
  },
  CasePowerToolsProps: {
    mainTitle: '',
    CasePowerToolsDetails: [
      {
        Title: 'Creating engaging brand experiences that extend to digital',
        para:
          'Red bull smartly uses photo booths at strategic locations at events they host to engage hundreds of thousands of event goers a year. We set out to create a fun Kiosk based solution using iPad that extended these physical experience to digital marketing channels.',
      },
      {
        para:
          'The kiosk iPad app offered various beautifully custom-made filters which arrange the clicked photos in 2*2 collage grid along with the event logo and colorful frame. With easy to use interface and fun filters, visitors preferred capturing group moment from the photo booth instead of using their device to click selfies. Captured photos can be later emailed to the visitors and lucky ones will get it featured on the Red Bull website or relevant social media accounts.',
      },
    ],
  },
  smallImgcontentProps: {
    heading: 'Kiosk mode for uninterrupted white labeled system',
    para:
      'As the app was to be used by the public, it was important that we prevent users to close or minimize the app knowingly or unknowingly. Once the app is installed, we enabled kiosk mode which can only be disabled by the system admin.',
    bottomPara:
      'We utilized Kiosk software by Scalefusion, an MDM solution provider. This enabled features like single app mode, lock/unlock the device remotely, auto download and install app updates flawlessly. It also offered geofencing techniques that can help in locating the device if goes missing.',
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “We needed an app experience that our customers would want to use at
            events. Simform brought this vision to life with an app that
            provided integrated, social, and beautiful events experiences.
          </span>{' '}
          Simform really gets this. And, they know how to create a mobile
          strategy with a profitable outcome.”
        </>
      ),
    },
  },
  RemotelycontrollsecProps: {
    title: 'Remotely controlled booths',
    bottomTitle: 'Implementation',
    bottomContent: 'Sending commands to remote photobooths in real-time',
    maincontent: (
      <>
        One of the limitations of contracting local vendors for photo-booth was
        the inability to update content and builds remotely in real-time. So,
        the first step in building the new photo-booth experience was to design
        an back-office system through which Redbull Team members can
        effortlessly design custom Photo templates and filters for ongoing as
        well as upcoming events.
        <br />
        <br />
        We built admin system for managing all aspects of photo-booth like
        restarting the device, modifying event branding, browsing photos
        captured during the event and generating reports.
      </>
    ),
    imgCaption:
      'Scalable backend architecture with Socket.io for realtime communication between client and server',
    bottompara:
      'Rather than going with a traditional API based approach, we utilized Socket.io to send commands to the iPad in real time to change the configuration of any iPad. It proved to be one of the most important features of the entire system as it helped them to deal with iPads during live events with almost zero latency.',
    trackheading:
      'Tracking & Analysing user engagement data for marketing team',
    trackpara:
      'We worked with Redbull marketing team to understand the marketing goals with events engagement. The solution is designed to give the marketing team reports and KPIs that they can use to achieve their goals. ',
    trackbottomPara:
      'iPad app collects engagement data points that marketing team uses for running targeted marketing campaign pre and post Red Bull events. We integrated Mixpanel to track user behaviour data and insights. We also integrated other social media analytics APIs to measure the success of various digital campaigns.',
    caseQuoteProps: {
      maintext: (
        <>
          <span>“This is a major first step.</span> Thank you all for your hard
          work <br />
          and dedication! ”
        </>
      ),
    },
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Stubbs & Woottons',
        para: 'Helping luxury shoe brand go digital',
        className: 'duck-egg-blue',
        link: 'case-studies/stubbs-woottons/',
      },
      {
        title: 'FIH',
        para:
          'Improving the online web experience for International Hockey Federation (FIH)',
        className: 'light-peach',
        link: 'case-studies/fih/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
