import styled from 'styled-components'
import { lg, md, xl } from 'config/variables'

export const CustomizablephotosecStyles = styled.div`
  margin-top: 114px;
  ${xl(`
    margin-top: 0;
  `)}
  ${lg(`
    margin-top: 0;
  `)}
  h2 {
    margin: 0;
    ${xl(`
      margin: 0 0 30px;
    `)}
     ${lg(`
      margin: 0 0 30px;
    `)}
     ${md(`
      margin: 0 0 20px;
    `)}
  }
  .row {
    display: flex;
    justify-content: space-between;
    ${lg(`
         flex-direction: column;
    `)}
    .col-content {
      flex: 0 1 500px;
      padding-top: 88px;
      ${xl(`
        padding-top: 0;
      `)}
       ${lg(`
       padding-top: 0;
        flex: 1 1 auto;
        padding-bottom:40px;
      `)}
      ${md(`
        padding-bottom:10px;
      `)}
    }
    .col-img {
      flex: 0 1 575px;
      ${lg(`
        flex: 1 1 auto;
      `)}
    }
  }
  .casequote-section {
    margin-top: 40px;
  }
`
