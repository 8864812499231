import React from 'react'
import { RemotelycontrollsecStyles } from './Remotelycontrollsec.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function Remotelycontrollsec(props) {
  const { RemotelycontrollsecProps } = props.remotecontrolsecdata
  const caseQuoteProp = RemotelycontrollsecProps.caseQuoteProps

  const {
    data: {
      scalableArchitech: {
        childImageSharp: { fluid: scalableArchitech },
      },
      mixpanel: {
        childImageSharp: { fluid: mixpanel },
      },
    },
  } = props
  return (
    <RemotelycontrollsecStyles>
      <div className="container">
        <div className="businessHeading">
          <h2>{RemotelycontrollsecProps.title}</h2>
        </div>
        <div className="engagementContent">
          <div className="left-part">
            <div className="bottom-content">
              <h4>{RemotelycontrollsecProps.bottomTitle}</h4>
              <p>{RemotelycontrollsecProps.bottomContent}</p>
            </div>
          </div>
          <div className="right-part">
            <p>{RemotelycontrollsecProps.maincontent}</p>
            <figure>
              <Image
                fluid={scalableArchitech}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 499,
                }}
              />
              <div className="img-caption">
                {RemotelycontrollsecProps.imgCaption}
              </div>
            </figure>
            <p>{RemotelycontrollsecProps.bottompara}</p>
          </div>
        </div>
        <div className="track-content">
          <h2>{RemotelycontrollsecProps.trackheading}</h2>
          <p>{RemotelycontrollsecProps.trackpara}</p>
          <div className="row">
            <div className="col">
              <figure>
                <Image
                  fluid={mixpanel}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 232,
                  }}
                />
              </figure>
            </div>
            <div className="col">
              <p>{RemotelycontrollsecProps.trackbottomPara}</p>
            </div>
          </div>
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </RemotelycontrollsecStyles>
  )
}

export default Remotelycontrollsec
